import { useFrame, useThree } from "@react-three/fiber";
import { RefObject, useEffect, useRef, useState } from "react";
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import { Box, CameraControls, Cone, FirstPersonControls, MeshReflectorMaterial, PerspectiveCamera, Text, useCursor } from "@react-three/drei";
import { useRoute, useLocation } from 'wouter'
import { Euler, EventDispatcher, Material, Quaternion, Vector3 } from 'three'

const GOLDENRATIO: number = 1.61803398875;
let activeObject: any = null;
// const imgUrl = (id: string) => `./assets/project-images/${id}`
const images = [
    // example project image
    { position: [-6, 2, -4.7], rotation: [0, 0, 0], url: 'MegaPrint-3D-Printer', scale: new Vector3(1.5, GOLDENRATIO / 1.25, 0.25) },
    { position: [-6, 2, 4.7], rotation: [0, Math.PI, 0], url: 'Custom-Mechanical-Keyboard', scale: new Vector3(1.5, GOLDENRATIO / 1.25, 0.25) },
    { position: [6, 2, -4.7], rotation: [0, 0, 0], url: 'Pentest-Pal', scale: new Vector3(1.5, GOLDENRATIO / 1.25, 0.25) },
    { position: [6, 2, 4.7], rotation: [0, Math.PI, 0], url: 'MX-9916-Night-Vision', scale: new Vector3(1.5, GOLDENRATIO / 1.25, 0.25) },
    { position: [-6, 2, 14.5], rotation: [0, Math.PI, Math.PI], url: 'test3', scale: new Vector3(1.5, GOLDENRATIO / 1.25, 0.25) },
    //Images below are hit boxes to navigate to other rooms
    { position: [0, 2, -7], rotation: [0, 0, 0], url: 'left-room' },
    //this one is for the temportary sign in the middle of the room
    { position: [-2, 2, 0], rotation: [0, (3 * Math.PI / 2), 0], url: 'contact' }
]
const plaques = [
    // 3D printer plaque
    { key: 1, description: "MegaPrint 3D-Printer", position: [-6, 1.25, -4.7], rotation: [(11*Math.PI)/6, 0, 0], scale: new Vector3(1, 0.3, 0.10) },
    { key: 2, description: "Custom Mechanical Keyboard", position: [-6, 1.40, 4.7], rotation: [0, Math.PI, 0], scale: new Vector3(1, 0.3, 0.10) },
    { key: 3, description: "Pentest Pal", position: [6, 1.25, -4.7], rotation: [(11*Math.PI)/6, 0, 0], scale: new Vector3(1, 0.3, 0.10) },
    { key: 4, description: "MX-9916 Night Vision", position: [6, 1.25, 4.7], rotation: [Math.PI/6, Math.PI, 0], scale: new Vector3(1, 0.3, 0.10) }
]

export default function PhotoFramesHitBoxes() {

    const [location, setLocation] = useLocation()
    const { controls, scene } = useThree<any>()
    const [, params] = useRoute<any>('/item/:id')
    const [hovered, hover] = useState(false)
    const [activeObject, setActiveObject] = useState(null)
    const cameraControlRef: RefObject<CameraControls> = useRef(null)
    const perspectiveCameraRef: RefObject<typeof PerspectiveCamera> = useRef(null)
    const firstPersonControlsRef: RefObject<any> = useRef(null)
    // const { controls, scene } = useThree()
    useCursor(hovered)

    //  sets the activeObject, this means the most recent object clicked is activeObject
    const handleClick = (event: any) => {
        if (event.object === activeObject) {
            setActiveObject(null)
            setLocation('/')    // set url location 
        } else {
            setActiveObject(event.object)
            setLocation('/' + event.object.name)    // set url location 
        }
    }

    //  resets the camera to default if there is no object clicked on
    const handlePointerMissed = () => {
        setActiveObject(null)
        cameraControlRef.current?.reset(true)
    }

    const moveToObject = (rect: any) => {
        const clickedOnObjectPosition = new Vector3(rect.parent.position.x, rect.parent.position.y, rect.parent.position.z)
        const rectCenterPosition = new Vector3()
        const rectNormal = new Vector3()
        const rectNormalForCamTarget = new Vector3()

        //copy object position
        rectCenterPosition.copy(clickedOnObjectPosition)

        //set a vector with position 1 unit in Z direction and rotate in the same position the target object is
        rectNormal.set(0, 0, 1).applyQuaternion(rect.quaternion)
        rectNormalForCamTarget.set(0, 0, 0.9).applyQuaternion(rect.quaternion)

        //set camear position to, rectCenterPosition + rectNormal postion, both are vectors
        //we end up sitting at the object positon but one unit away from the object
        const cameraPosition = rectCenterPosition.clone().add(rectNormal)
        
        // //adjusting the camera target to be right in front of camera positon to give first person effect
        rectCenterPosition.add(rectNormalForCamTarget)

        //set camera controls to cameraPosition and rectCenterPosition
        cameraControlRef.current?.setLookAt(
            cameraPosition.x,
            cameraPosition.y,
            cameraPosition.z,
            rectCenterPosition.x,
            rectCenterPosition.y,
            rectCenterPosition.z,
            true,
        )
    }

    //  whenver activeObject is updated, the camera is mutated using fitToRec()
    //  or default depending on what the activeObject is set to
    useEffect(() => {
        if (cameraControlRef.current != undefined) {
            if (activeObject != undefined) {
                moveToObject(activeObject)
            } else {
                handlePointerMissed()
                setLocation('/')    // set url location 
            }
        }
    }, [activeObject])

    //  sets the initial position and target, then saves that camera state
    //  this is also considered the default camera position/target
    useEffect(() => {
        cameraControlRef.current?.setPosition(-10, 2, 0, true)
        cameraControlRef.current?.setTarget(-9.5, 2, 0, true)
        cameraControlRef.current?.saveState()
    }, [])

    return (
        <>
            <group>
                {
                    images.map((props: any) => <HitBox key={props.url} {...props} scale={props.scale}
                        onClick={(event: any) => handleClick(event)}
                        onPointerMissed={() => handlePointerMissed()} />)
                }
                {
                    plaques.map((props: any) => <Plaque key={props.key} {...props} scale={props.scale} />)
                }
            </group >
            <CameraControls ref={cameraControlRef} makeDefault minZoom={0.02} maxZoom={5} />
        </>
    );
}

function HitBox({ url, ...props }: { url: string;[key: string]: any }) {
    const [hovered, hover] = useState(false)
    const name = url
    useCursor(hovered)

    return (
        <group position={props.position} rotation={props.rotation} {...props}>
            <Box
                name={name}
                // material-color="#FF0000"
                onPointerOver={(e) => (e.stopPropagation(), hover(true))}
                onPointerOut={() => hover(false)}
                scale={props.scale}
                //position={[0, GOLDENRATIO / 1.5, 0]}
                rotation={props.rotation}
            >
                <meshBasicMaterial transparent={true} opacity={0} wireframe />

            </Box>
        </group>
    )
}

function Plaque({...props }: {[key: string]: any }) {

    return (
        <group position={props.position} rotation={props.rotation} {...props}>
            <Box scale={props.scale} >
                {/* <MeshReflectorMaterial
                    blur={[300, 100]}
                    resolution={480}
                    mixBlur={1}
                    mixStrength={80}
                    roughness={0}
                    depthScale={1.2}
                    minDepthThreshold={0.4}
                    maxDepthThreshold={1.4}
                    color="#FFD700"
                    metalness={0.5}
                    mirror={0.5}
                /> */}
            </Box>
            <Text color={"black"} maxWidth={1.1} anchorX="center" anchorY="top" position={[0.0, 0, 0.08]} fontSize={0.07} font={"/assets/fonts/Roboto/Roboto-Light.ttf"} >
                {props.description}
            </Text>
        </group>
    )
}