import { CameraControls, ContactShadows, FaceControls, FirstPersonControls, Gltf, OrbitControls, OrthographicCamera, PerspectiveCamera, Shadow, Sky, SoftShadows, Sphere, Text3D } from "@react-three/drei";
import { DoubleSide, MathUtils, MeshBasicMaterial, MeshStandardMaterial } from "three";
import TempSign from "./components/Sign";
import PhotoFramesHitBoxes from "./components/photo-frames/PhotoFramesHitBoxes";
import Terrarium from "./components/terrarium/Terrarium";

export default function Scene() {
  return (
    <>
      <Sky />

      {/* under construction sign */}
      <TempSign />

      <PhotoFramesHitBoxes />
      <ContactShadows position={[0, -1, 0]} blur={3} />

      {/* <Terrarium /> */}

      {/* <group position={[-8, 2, 0]}>
        <Sphere>
          <meshStandardMaterial color="red"/>
        </Sphere>
      </group> */}
      <ambientLight intensity={0.5}/>

      {/* <Gltf src="/assets/3d-models/art_gallery/scene-no-zen.glb" receiveShadow={true} castShadow={true} deep={false} /> */}
      <Gltf src="/assets/3d-models/art_gallery/scene.gltf" receiveShadow={true} castShadow={true} deep={false} />
      <directionalLight position={[-10.766664600520382, 24.1369052777617, 13.825767820476647]} castShadow shadow-mapSize={[2048, 2048]} shadow-bias={-.001} shadow-camera-near={-5} shadow-camera-far={50} shadow-camera-left={-50} shadow-camera-right={50} shadow-camera-bottom={-50} shadow-camera-top={50} args={[undefined, 3]} />
    </>
  );
}
