import React, { Suspense, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Canvas } from '@react-three/fiber';
import Scene from './scene';
import Loader from './components/loading-screen/Loader';
import { PerformanceMonitor } from '@react-three/drei';
import { useControls } from 'leva';
import { Perf } from 'r3f-perf';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Canvas shadows={'variance'} >
          <Perf position="top-right" />
          <Scene />
      </Canvas>
    </Suspense>
  );
}

export default App;
