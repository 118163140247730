import { Html, useProgress } from '@react-three/drei'
import './loader.scss';

export default function Loader() {
  const { progress } = useProgress()

  return (
    <div className='div-container'>
        <div className='loading-text'>
            <h2>Loading Alvaro's Exhibit</h2>
            <small>Click any object to get a closer look</small>
        </div>
        {/* <div className='loading-progress'>
            {progress} % loaded
        </div> */}
        <div className="loading-progress"><div></div><div></div><div></div></div>
    </div>
  )
}